/* eslint-disable */
import { EmployeeModule } from 'Modules/CTMModules';
import { get } from 'helpers/Axios';
import { ActionPattern, call, put, takeEvery } from 'redux-saga/effects';
import { GET_CONFIGURATION } from 'store/Employee/Configuration/ActionTypes';
import { setConfiguration } from 'store/Employee/Configuration/Actions';

function* getConfiguration({ payload: {} }) {
  try {
    const response = yield call(ctx => get(EmployeeModule.configuration.api.item.getMyConfiguration), {});
    yield put(setConfiguration(response ?? []));
  } catch (error) {}
}

function* configurationSaga() {
  yield takeEvery<ActionPattern, any>(GET_CONFIGURATION, getConfiguration);
}

export default configurationSaga;
